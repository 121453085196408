<template>
  <v-container class="edit-container px-10" fluid>
    <v-diglog-confirm
      :show="isLeave"
      :subTitle="subTitle"
      :close="close"
      :buttonText="buttonConfirmText"
      :confirm="confirm"
      :buttonCancel="buttonCancel"
    />
    <v-alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      :subTitle="subTitleDelete"
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isDeleteSubPackage"
      title="Remove Confirmation"
      :subTitle="subTitleDeleteSubPackage"
      :close="closeDeletePopupSubPackage"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDeleteSubPackage"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      t-data="cancel-popup"
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      t-data="save-popup"
      :show="isSave"
      subTitle='Want to continue managing sub package? The package is created successfully. To complete the package setup, you requires to create sub package into the package, click "Continue" to proceed. Or else complete it later by clicking "Cancel".'
      headline-color="black"
      buttonText="Continue"
      :close="onCreatedCancel"
      :confirm="onContinue"
      confirm-btn-color="info600Color"
      icon=""
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isReset"
      title="Reset Confirmation"
      subTitle="Do you want to reset all changes?"
      headline-color="black"
      button-cancel="OK"
      button-text="Cancel"
      :close="handleReset"
      :confirm="closeResetPopup"
      icon=""
    ></v-alert-confirm>

    <v-alert-error
      :show="deleteError"
      :close="closeDeleteErrorAlert"
      :msg="deleteErrorMsg"
    ></v-alert-error>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error
      :show="isError"
      :close="closeAlert"
      :msg="imageEnErrorMessage"
    ></v-alert-error>
    <v-share-link
      :show="isShare"
      :package-id="packageObject.id"
      :close="closeSharePopup"
    ></v-share-link>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :orgs="previewOrgs"
      :data-object="packageObject"
      :sub-package-list="subPackageList"
    />

    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row
          class="align-center"
          style="margin-left: 1px; margin-right: 0px; margin-right: 0px;"
        >
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Package
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete"
            :show-reset="true"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-reset="disabledEdit || disabledReset"
            :disabled-preview="isDisabledPreview()"
            :on-remove="showDeletePopup"
            :on-reset="showResetPopup"
            :on-preview="showPreview"
            :on-cancel="showCancelPopup"
            :on-save="save"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-form ref="packageTypeForm" lazy-validation>
      <v-card v-if="showPackageTypeSection" t-data="package-type">
        <v-card-title class="mb-0 pt-6 px-6" style="line-height: 24px">
          <v-row>
            <v-col cols="12" sm="4" class="mt-0 pt-0 pb-0" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Package Type <span class="danger500Color--text">*</span>
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Package Type"
                v-model="packageObject.package_type"
                :menu-props="{bottom: true, offsetY: true}"
                :items="packageTypes"
                item-value="value"
                item-text="label"
                @change="changePackageType"
                :rules="[packageTypeRules.required]"
                :hide-details="hideDetailsPackageType"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <v-card v-if="!showPackageTypeSection" t-data="package-info">
      <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
        <h6 class="mb-0 card-title" t-data="info-title">
          Package Group Information
        </h6>
        <v-spacer></v-spacer>
        <v-btn
          t-data="share-btn"
          color="borderColor"
          outlined
          class="sub-body-bold btn-share"
          v-if="packageObject.id"
          @click="showSharePopup"
        >
          <v-icon left color="primaryTextColor">
            mdi-launch
          </v-icon>
          <div class="text-btn">
            Share
          </div>
        </v-btn>
        <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
          Status
        </p>
        <v-switch
          color="primary"
          v-model="packageObject.status"
          :false-value="1"
          :true-value="0"
          class="mb-0 pb-0"
          :disabled="disabledEdit"
        ></v-switch>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-0">
        <v-form ref="form" lazy-validation>
          <v-row align="start" justify="start" class="mt-0 mb-4">
            <v-col cols="6" class="mt-0 pt-0">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Package Type <span class="danger500Color--text">*</span>
              </p>
              <v-select
                outlined
                dense
                class="can-disable-selector"
                placeholder="Select Package Type"
                v-model="packageObject.package_type"
                :menu-props="{bottom: true, offsetY: true}"
                :items="packageTypes"
                item-value="value"
                item-text="label"
                @change="changePackageType"
                :hide-details="true"
                :disabled="isEditMode"
                :rules="[packageTypeRules.required]"
              ></v-select>
              <v-row
                v-if="!isEditMode"
                align="center"
                justify="start"
                class="flex-nowrap"
                no-gutters
              >
                <p class="value-message small mb-0 warning800Color--text">
                  The Package type cannot be edited. Once the item has been
                  saved.
                </p>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="d-flex justify-start align-center"
              t-data="info-input"
            >
              <p
                class="edit-field-label sub-body-bold mr-6"
                t-data="info-label"
              >
                Channel
              </p>
              <v-radio-group
                v-model="packageObject.display_type"
                @change="changeDisplayType"
                row
              >
                <v-radio
                  v-for="displayType in displayTypes"
                  :key="displayType.value"
                  :value="displayType.value"
                  :label="displayType.label"
                  :disabled="isEditMode"
                  color="secondary"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-if="!isGroupPackage"
            align="start"
            justify="start"
            class="mt-0 mb-4"
          >
            <v-col cols="6" class="mt-0 pt-0">
              <v-row class="mb-4">
                <v-col cols="12">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Product List Price
                    <span class="danger500Color--text">*</span>
                  </p>
                  <v-text-field
                    id="product-list-price-text-box"
                    outlined
                    dense
                    required
                    :rules="[fullPriceRules.required, fullPriceRules.valid]"
                    @keypress="isNumber"
                    class="subtitle-2 can-disable-selector"
                    onkeypress="if(this.value.length==9) return false;"
                    v-model="fullPrice"
                    placeholder="Product List Price (Baht)"
                    @input="fullPriceInputed"
                    :disabled="disabledEdit"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Discount Type
                  </p>
                  <v-select
                    outlined
                    dense
                    class="can-disable-selector"
                    v-model="packageObject.discount_type"
                    :menu-props="{bottom: true, offsetY: true}"
                    :items="discountTypes"
                    item-value="value"
                    item-text="label"
                    @change="changedDiscountType"
                    :disabled="disabledEdit"
                  ></v-select>
                </v-col>
                <v-col cols="7">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Value
                  </p>
                  <v-text-field
                    class="can-disable-selector"
                    id="value-text-box"
                    :hide-details="showWarningDiscount"
                    @blur="blurDiscountValueError"
                    @update:error="setDiscountValueError"
                    outlined
                    required
                    dense
                    @keypress="isNumber"
                    :rules="
                      packageObject.discount_type === 'NO_DISCOUNT'
                        ? []
                        : [
                            discountValueRules.lessThanOrEqualZero,
                            discountValueRules.required
                          ]
                    "
                    :prefix="discountTypePrefix"
                    :disabled="isDisableDiscountType || disabledEdit"
                    v-model="packageObject.discount"
                    @change="discountChange"
                  ></v-text-field>
                  <v-row
                    v-if="showWarningDiscount"
                    align="center"
                    justify="start"
                    class="flex-nowrap"
                    no-gutters
                  >
                    <v-img
                      aspect-ratio="1"
                      class="value-message-icon mt-0 mr-1 ml-1"
                      max-height="16px"
                      max-width="16px"
                      src="/images/icons/info-icon.png"
                    ></v-img>
                    <p
                      class="value-message secondaryTextColor--text small mb-0"
                    >
                      {{ discountIsZeroErrorMessage }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <div
              class="mx-auto pt-0"
            >
              <sale-price-preview
                :key="`sale-price-preview-${componentKey}`"
                :is-show-price-first-create="isShowPriceFirstCreate"
                :full-price="packageObject.full_price"
                :discount="isDisableDiscountType ? 0 : discountValue"
                :discount-type="packageObject.discount_type"
                @error="e => (isPricePreviewError = e)"
              />
            </div>
          </v-row>
          <v-row align="start" justify="start" class="mt-0">
            <v-col v-if="!isGroupPackage" cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Centrix Code <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                outlined
                required
                dense
                class="subtitle-2 can-disable-selector"
                v-model="packageObject.centrix_code"
                placeholder="Enter Centrix Code of Product"
                :rules="[centrixCodeRules.required]"
                :disabled="disabledEdit"
              ></v-text-field>
            </v-col>
            <v-col :cols="isGroupPackage ? 4 : 3">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :branch="branchs"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                :isEditMode="isEditMode"
                :is-disabled="disabledEdit"
                v-if="!isLoading"
                @change="resetDepartment"
              ></single-selected-branch>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Department <span class="danger500Color--text">*</span>
              </p>
              <v-select
                class="can-disable-selector"
                placeholder="Select Department"
                :items="departmentList"
                outlined
                required
                color="primary500Color"
                dense
                v-model="packageObject.department_id"
                item-text="name"
                item-value="id"
                :rules="[departmentRules.required]"
                :disabled="departmentList.length <= 0 || disabledEdit"
              >
              </v-select>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Availability Status <span class="danger500Color--text">*</span>
              </p>
              <v-select
                class="can-disable-selector"
                placeholder="Select Status"
                :items="[
                  {value: true, text: 'Available'},
                  {value: false, text: 'Unavailable'}
                ]"
                outlined
                required
                color="primary500Color"
                dense
                v-model="packageObject.available_status"
                item-text="text"
                item-value="value"
                :rules="[availabilityStatusRules.required]"
                :disabled="disabledEdit"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0">
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish Start Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select Start Date"
                v-model="packageObject.period_start_date"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[publishStartDateRules.required]"
                @input="startDateChange"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish Start Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select Start Time"
                v-model="packageObject.period_start_time"
                @input="startTimeChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[
                  publishStartTimeRules.required,
                  publishStartTimeRules.startTime
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish End Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select End Date"
                v-model="packageObject.period_end_date"
                @input="endDateChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[publishEndDateRules.required]"
                :allowedDates="allowedDates"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Publish End Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select End Time"
                v-model="packageObject.period_end_time"
                @input="endTimeChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[
                  publishEndTimeRules.required,
                  publishEndTimeRules.endTime
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Validity Start Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select Start Date"
                v-model="packageObject.validity_start_date"
                @input="validityStartDateChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[validityStartDateRules.required]"
                :allowedDates="allowedValidityStartDates"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Validity Start Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select Start Time"
                v-model="packageObject.validity_start_time"
                @input="validityStartTimeChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[
                  validityStartTimeRules.required,
                  validityStartTimeRules.timeMoreThanCurrent,
                  validityStartTimeRules.timeMoreThanEqualStart
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Validity End Date <span class="danger500Color--text">*</span>
              </p>
              <custom-date-picker
                placeholder="Select End Date"
                v-model="packageObject.validity_end_date"
                @input="validityEndDateChange"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[validityEndDateRules.required]"
                :allowedDates="allowedValidityEndDates"
                outlined
                required
              ></custom-date-picker>
            </v-col>
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Validity End Time <span class="danger500Color--text">*</span>
              </p>
              <time-picker
                placeholder="Select End Time"
                v-model="packageObject.validity_end_time"
                :isDisable="isDisableAfterPublish || disabledEdit"
                :rule="[
                  validityEndTimeRules.required,
                  validityEndTimeRules.startTimeMoreThanEnd,
                  validityEndTimeRules.publishEndTimeMoreThanEnd
                ]"
                outlined
                required
              ></time-picker>
            </v-col>
          </v-row>
          <v-row align="start" justify="start" class="mt-0 mb-4">
            <v-col
              cols="6"
              class="mt-0 pt-0 d-flex justify-start align-center"
              t-data="info-input"
            >
              <p
                class="edit-field-label sub-body-bold mr-4"
                t-data="info-label"
              >
                Highlight
              </p>
              <v-switch
                color="primary200Color"
                class="mb-0 pb-0 custom-switch"
                v-model="packageObject.is_highlight"
                :false-value="0"
                :true-value="1"
                :disabled="disabledEdit"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row
            v-if="isWebSection"
            align="start"
            justify="start"
            class="mt-0 mb-4"
            t-data="info-input"
          >
            <v-col cols="6" class="mt-0 pt-0" t-data="info-label">
              <p
                class="edit-field-label sub-body-bold mr-4"
                t-data="status-title"
              >
                Tags
              </p>
              <v-combobox
                class="combobox-tag can-disable-selector"
                v-model="selectedTags"
                placeholder="Input Title Tags"
                @input="handleInputTags"
                chips
                multiple
                outlined
                dense
                hide-details
                :disabled="disabledEdit"
              >
                <template v-slot:selection="{attrs, item, select, selected}">
                  <v-chip
                    class="body-regular custom-chip"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeTag(item)"
                    color="info200Color"
                    text-color="info800Color"
                    close-icon="mdi-close"
                    :disabled="disabledEdit"
                  >
                    {{ item }}&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <!-- Contents -->
          <package-content
            :key="`package-content-${componentKey}`"
            :disabledEdit="disabledEdit"
          ></package-content>
          <meta-content
            v-if="isWebSection"
            :key="`meta-content-${componentKey}`"
            :disabledEdit="disabledEdit"
          ></meta-content>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-if="isGroupPackage" class="mt-6">
      <v-alert
        v-if="!hasPermEdit"
        class="warning800Color--text body-regular px-2 py-1 mb-0"
        color="warning300Color"
        t-data="warning-alert"
        dense
        no-gutters
      >
        <div class="d-flex align-center justify-start">
          <v-img
            class="mr-2 warning600Color--text"
            max-height="24px"
            max-width="24px"
            src="/images/icons/warning-yellow-icon.png"
          ></v-img>
          <div style="line-height: 28px;">
            You cannot edit information because you do not have permission to edit
            package.
          </div>
        </div>
      </v-alert>
      <v-row class="align-center mb-4 mx-4 pt-6" style="margin-left: 1px;">
        <h6 class="pageHeadline" t-data="page-headline">
          Sub package list
        </h6>
        <v-spacer></v-spacer>
        <v-btn
          v-if="hasPermEdit"
          id="add-btn"
          large
          color="primary600Color"
          class="white-text-button button-bold"
          t-data="add-btn"
          :disabled="!isEditMode"
          @click="addSubPackage"
        >
          <v-icon left>mdi-plus-circle-outline</v-icon>Add Sub Package
        </v-btn>
      </v-row>
      <v-data-table
        class="elevation-1"
        :loading="isLoadingSubPackage"
        :items="subPackageList"
        :headers="subPackageHeaders"
        no-data-text="Please complete creating the main package."
      >
        <template v-slot:item.content="{item}" style="width: 418px">
          <tooltip-title :contents="item.contents">
            <template v-slot:content>
              <v-list-item-content
                class="d-inline-block text-truncate"
                style="width: 418px"
              >
                <v-list-item-title>
                  {{
                    item.contents[0].code === 'th'
                      ? item.contents[0].title
                      : item.contents[1].title
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{
                    item.contents[0].code === 'en'
                      ? item.contents[0].title
                      : item.contents[1].title
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </tooltip-title>
        </template>
        <template v-slot:item.price="{item}">
          ฿ {{ convertNumToComma(item.price) }}
        </template>
        <template v-slot:item.action="{item}">
          <div>
            <v-icon
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item)"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-if="hasPermEdit"
              t-data="delete-action"
              class="px-2 action-icon"
              @click="showSubPackageDeletePopup(item.id)"
              >mdi-trash-can-outline</v-icon
            >
            <v-icon
              v-else
              t-data="empty-icon"
              class="px-2 action-icon transparent-icon"
            ></v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row
      v-if="!showPackageTypeSection"
      class="d-flex align-start mb-3"
      t-data="footer-row"
      style="margin-left: 1px; margin-right: 0;"
    >
      <created-detail v-if="isEditMode" :data-object="packageObject" />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete"
        :show-reset="true"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-reset="disabledEdit || disabledReset"
        :disabled-preview="isDisabledPreview()"
        :on-remove="showDeletePopup"
        :on-reset="showResetPopup"
        :on-preview="showPreview"
        :on-cancel="showCancelPopup"
        :on-save="save"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  isUrl,
  convertIntegerToCommaNumber,
  convertCommaNumberToInteger,
  convertSelectedData,
  hasPermissionsByOrgs,
  snackbarSuccessContent,
  snackbarFailContent
} from '../../helper/helper'
import {mapState, mapActions} from 'vuex'
import CustomDatePicker from '../../components/DatePickerWithMin'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import CreatedDetail from '../../components/CreatedDetail'
import ShareLinkPopup from '@/views/package/ShareLinkPopup'
import Confirm from '../../components/AlertConfirm'
import moment from 'moment'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {
  discountTypeText,
  discountTypeEnum,
  packageTypeText,
  packageTypeEnum,
  displayTypeText,
  displayTypeEnum
} from '@/constants/package'
import EditButtons from '@/components/EditButtons'
import AlertConfirm from '@/components/AlertConfirm'
import TimePicker from '@/components/TimePicker'
import {
  timeMoreThanCurrentRule,
  timeMoreThanEqualStartRule,
  timeMoreThanStartRule
} from '@/helper/rule'
import Preview from '@/components/package/Preview'
import PackageContent from '@/components/package/PackageContent'
import MetaContent from '@/components/package/MetaContent'
import TooltipTitle from '@/components/TooltipTitle'
import SalePricePreview from '@/components/package/SalePricePreview'
import SnackBar from '@/components/SnackBar'
import {actionEnum} from '@/constants/constants'

export default {
  components: {
    'custom-date-picker': CustomDatePicker,
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-diglog-confirm': Confirm,
    'v-popup-loading': PopupLoading,
    'v-share-link': ShareLinkPopup,
    PackageContent,
    CreatedDetail,
    EditButtons,
    Preview,
    SalePricePreview,
    SingleSelectedBranch,
    SnackBar,
    TimePicker,
    TooltipTitle,
    MetaContent
  },
  data() {
    return {
      pageName: 'Sub Package',
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'created',
      isReset: false,
      componentKey: 0,
      selectedTags: [],
      previewOrgs: [],
      clickedSave: false,
      isShowPriceFirstCreate: true,
      isPricePreviewError: false,
      deleteError: false,
      deleteErrorMsg:
        'Package cannot delete right now. Please try again later.',
      isEngContentExpand: true,
      isThaiContentExpand: true,
      buttonConfirmDeleteText: 'Delete',
      subTitleDelete:
        'Are you sure you want to remove this package? This action will remove content in both Thai and English.',
      subTitleDeleteSubPackage:
        'Are you sure you want to remove sub package? This action will remove content in both Thai and English.',
      deleteId: 0,
      discountValue: null,
      isError: false,
      discountIsZeroErrorMessage: 'Input value more than 0',
      buttonConfirmText: 'Leave',
      buttonCancel: 'Stay',
      subTitle:
        'You have changed the information.\nAre you sure to leave this page?',
      isPreview: false,
      isCancel: false,
      previewItem: null,
      to: null,
      isLeave: false,
      showDialog: false,
      isSave: false,
      isPackageTypeError: false,
      isFullPriceError: false,
      isDiscountError: false,
      isCentrixCodeError: false,
      isDepartmentError: false,
      isAvailabilityStatusError: false,
      isPublishStartDateError: false,
      isPublishStartTimeError: false,
      isPublishEndDateError: false,
      isPublishEndTimeError: false,
      isValidityStartDateError: false,
      isValidityStartTimeError: false,
      isValidityEndDateError: false,
      isValidityEndTimeError: false,
      packageTypeRules: {
        required: value => {
          if (value) {
            this.isPackageTypeError = false
            return true
          }
          this.isPackageTypeError = true
          return 'Package Type is required'
        }
      },
      fullPriceRules: {
        required: value => {
          if (value) {
            this.isFullPriceError = false
            return true
          }
          this.isFullPriceError = true
          return 'Please enter price.'
        },
        valid: value => {
          if (!value) {
            this.isFullPriceError = true
            return 'Please enter price.'
          }
          if (!value && convertCommaNumberToInteger(value) <= 0) {
            this.isFullPriceError = true
            return `Full price must be greater than 0`
          }

          return true
        }
      },
      discountValueRules: {
        required: value => {
          if (value) {
            this.isDiscountError = false
            return true
          }
          this.isDiscountError = true
          return 'Value is required'
        },
        lessThanOrEqualZero: value => {
          if (parseInt(value) <= 0) {
            this.isDiscountError = true
            return this.discountIsZeroErrorMessage
          }
          return true
        }
      },
      centrixCodeRules: {
        required: value => {
          if (value) {
            this.isCentrixCodeError = false
            return true
          }
          this.isCentrixCodeError = true
          return 'Centrix Code is required'
        }
      },
      departmentRules: {
        required: value => {
          if (value) {
            this.isDepartmentError = false
            return true
          }
          this.isDepartmentError = true
          return 'Department is required'
        }
      },
      availabilityStatusRules: {
        required: value => {
          if (value !== null) {
            this.isAvailabilityStatusError = false
            return true
          }
          this.isAvailabilityStatusError = true
          return 'Availability Status is required'
        }
      },
      publishStartDateRules: {
        required: value => {
          if (value) {
            this.isPublishStartDateError = false
            return true
          }
          this.isPublishStartDateError = true
          return 'Date is required'
        }
      },
      publishStartTimeRules: {
        required: value => {
          if (value) {
            this.isPublishStartTimeError = false
            return true
          }
          this.isPublishStartTimeError = true
          return 'Time is required'
        },
        startTime: value => {
          const result = this.startTimeRule(value)
          if (typeof result === 'string') {
            this.isPublishStartTimeError = true
            return result
          }
          return result
        }
      },
      publishEndDateRules: {
        required: value => {
          if (value) {
            this.isPublishEndDateError = false
            return true
          }
          this.isPublishEndDateError = true
          return 'Date is required'
        }
      },
      publishEndTimeRules: {
        required: value => {
          if (value) {
            this.isPublishEndTimeError = false
            return true
          }
          this.isPublishEndTimeError = true
          return 'Time is required'
        },
        endTime: value => {
          const result = this.endTimeRule(value)
          if (typeof result === 'string') {
            this.isPublishEndTimeError = true
            return result
          }
          return result
        }
      },
      validityStartDateRules: {
        required: value => {
          if (value) {
            this.isValidityStartDateError = false
            return true
          }
          this.isValidityStartDateError = true
          return 'Date is required'
        }
      },
      validityStartTimeRules: {
        required: value => {
          if (value) {
            this.isValidityStartTimeError = false
            return true
          }
          this.isValidityStartTimeError = true
          return 'Time is required'
        },
        timeMoreThanCurrent: value => {
          const result = this.validityStartTimeMoreThanCurrentRule(value)
          if (typeof result === 'string') {
            this.isValidityStartTimeError = true
            return result
          }
          return result
        },
        timeMoreThanEqualStart: value => {
          const result = this.validityStartTimeMoreThanEqualStartRule(value)
          if (typeof result === 'string') {
            this.isValidityStartTimeError = true
            return result
          }
          return result
        }
      },
      validityEndDateRules: {
        required: value => {
          if (value) {
            this.isValidityEndDateError = false
            return true
          }
          this.isValidityEndDateError = true
          return 'Date is required'
        }
      },
      validityEndTimeRules: {
        required: value => {
          if (value) {
            this.isValidityEndTimeError = false
            return true
          }
          this.isValidityEndTimeError = true
          return 'Time is required'
        },
        startTimeMoreThanEnd: value => {
          const result = this.validityStartTimeMoreThanEndRule(value)
          if (typeof result === 'string') {
            this.isValidityEndTimeError = true
            return result
          }
          return result
        },
        publishEndTimeMoreThanEnd: value => {
          const result = this.publishEndTimeMoreThanEndRule(value)
          if (typeof result === 'string') {
            this.isValidityEndTimeError = true
            return result
          }
          return result
        }
      },
      isEditMode: false,
      isDuplicateMode: false,
      isShare: false,
      createdPackageId: null,
      subPackageHeaders: [
        {
          text: 'Sub Package Title',
          align: 'left',
          sortable: false,
          value: 'content',
          width: 418
        },
        {
          text: 'Centrix code',
          align: 'left',
          sortable: false,
          value: 'centrix_code',
          width: 222
        },
        {
          text: 'Sale Price (Baht)',
          align: 'left',
          sortable: false,
          value: 'price',
          width: 200
        },
        {
          text: 'Action',
          align: 'left',
          sortable: false,
          value: 'action',
          width: 143
        }
      ],
      disabledReset: true
    }
  },
  destroyed() {
    this.resetState()
    this.resetList()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetList()
  },
  async created() {
    await this.fetchDetails()
    this.selectedTags = this.packageObject.tags.map(t => t.value)
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  computed: {
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    isGroupPackage() {
      return this.packageObject.package_type === packageTypeEnum.GROUP
    },
    showPackageTypeSection() {
      return !this.isEditMode && this.packageObject.package_type === null
    },
    isWebSection() {
      return (
        this.packageObject.display_type === displayTypeEnum.ALL ||
        this.packageObject.display_type === displayTypeEnum.WEB
      )
    },
    hideDetailsPackageType() {
      return this.isPackageTypeError && !this.clickedSave
    },
    orgName() {
      const filteredOrg = this.packageObject.orgs.map(org =>
        this.orgObject.find(ob => ob.value === org)
      )
      return filteredOrg.map(o => o.text).join(', ')
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditPackage', this.packageObject.orgs)
      )
    },
    hasPermEdit() {
      return hasPermissionsByOrgs('CanEditPackage', this.packageObject.orgs)
    },
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeletePackage', this.packageObject.orgs)
    },
    hasPermCreate() {
      return hasPermissionsByOrgs('CanAddPackage', this.packageObject.orgs)
    },
    showWarningDiscount() {
      return (
        this.packageObject.discount_type !== discountTypeEnum.NO_DISCOUNT &&
        !this.isDiscountError
      )
    },
    packageTypes() {
      const types = []
      for (let key in packageTypeText) {
        if (packageTypeText.hasOwnProperty(key)) {
          types.push({label: packageTypeText[key], value: key})
        }
      }
      return types
    },
    displayTypes() {
      const types = []
      for (let key in displayTypeText) {
        if (displayTypeText.hasOwnProperty(key)) {
          types.push({label: displayTypeText[key], value: key})
        }
      }
      return types
    },
    discountTypes() {
      const types = []
      for (let key in discountTypeText) {
        if (discountTypeText.hasOwnProperty(key)) {
          types.push({label: discountTypeText[key], value: key})
        }
      }
      return types
    },
    isDisableDiscountType() {
      return this.packageObject.discount_type === discountTypeEnum.NO_DISCOUNT
    },
    discountTypePrefix() {
      if (this.packageObject.discount_type === discountTypeEnum.PRICE) {
        return '฿'
      } else if (
        this.packageObject.discount_type === discountTypeEnum.PERCENTAGE
      ) {
        return '%'
      } else {
        return ''
      }
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.packageObject.orgs && this.packageObject.orgs.length > 0) {
        selectedBranch = this.packageObject.orgs[0]
      }
      return selectedBranch
    },
    menuProps() {
      return this.packageObject.orgs && this.packageObject.orgs.length > 0
        ? {value: false, overflowY: true}
        : {overflowY: true}
    },
    branchs() {
      return this.orgObject.map(o => o.value)
    },
    previewObject() {
      let previewObject = this.packageObject
      previewObject.content = this.packageObject.contents
      return previewObject
    },
    fullPrice: {
      get() {
        const commaNum = convertIntegerToCommaNumber(
          this.packageObject.full_price
        )
        return commaNum === '0' ? '' : commaNum
      },
      set(value) {
        if (
          value !== '' &&
          Number.isInteger(convertCommaNumberToInteger(value))
        ) {
          this.packageObject.full_price = convertCommaNumberToInteger(value)
        }
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Package & Promotion', links: true, to: '/packages'},
        {text: `${this.textMode} Package`, links: false}
      ]
    },
    ...mapState('subPackage', {
      subPackageList: 'subPackageList',
      isDeleteSubPackage: 'isDelete',
      isLoadingSubPackage: 'isLoading'
    }),
    ...mapState('packages', [
      'departmentList',
      'duplicateContent',
      'duplicateId',
      'imageEnErrorMessage',
      'imageThErrorMessage',
      'isCoverImageEnError',
      'isCoverImageThError',
      'isDelete',
      'isDisableAfterPublish',
      'isImageEnError',
      'isImageThError',
      'isLoading',
      'organizationList',
      'orgObject',
      'orgs',
      'packageObjOld',
      'packageObject',
    ])
  },
  watch: {
    $route: function() {
      this.fetchDetails()
    },
    duplicateContent: {
      handler() {
        function deepEqualWithJSON(obj1, obj2) {
          return JSON.stringify(obj1) === JSON.stringify(obj2)
        }
        const isNotCheckDuplicate = Object.values(this.duplicateContent).every(
          v => v === false
        )
        this.disabledReset =
          deepEqualWithJSON(this.packageObject, this.packageObjOld) &&
          isNotCheckDuplicate
      },
      deep: true
    },
    packageObject: {
      handler() {
        function deepEqualWithJSON(obj1, obj2) {
          return JSON.stringify(obj1) === JSON.stringify(obj2)
        }
        const isNotCheckDuplicate = Object.values(this.duplicateContent).every(
          v => v === false
        )
        this.disabledReset =
          deepEqualWithJSON(this.packageObject, this.packageObjOld) &&
          isNotCheckDuplicate
      },
      deep: true
    }
  },
  methods: {
    assignTags(values) {
      this.packageObject.tags = values.map(v => ({value: v}))
    },
    handleInputTags(values) {
      this.assignTags(values)
    },
    removeTag(item) {
      this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
      this.assignTags(this.selectedTags)
    },
    async fetchDetails() {
      this.setIsLoading(true)
      this.isEditMode = !!this.$route.params.id
      this.isDuplicateMode = !!this.duplicateId
      this.setEditMode(this.isEditMode)
      if (this.isEditMode) {
        this.isShowPriceFirstCreate = false
        await this.setPackageId(this.$route.params.id)
        this.discountValue = this.packageObject.discount
        this.scrollToTop()
        await this.fetch(this.packageObject.id)
      } else if (this.isDuplicateMode) {
        this.isShowPriceFirstCreate = false
        await this.setDuplicatePackageId(this.duplicateId)
        this.discountValue = this.packageObject.discount
        if (this.isWebSection) await this.setAlias(this.packageObject.alias)
        this.scrollToTop()
      } else {
        this.resetState()
        this.resetList()
      }

      const isViewOnly =
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditPackage', this.packageObject.orgs)
      await this.setOrgInfo(isViewOnly)
      this.setIsLoading(false)
    },
    onCreatedCancel() {
      this.$router.push({
        name: 'package-list',
        query: {isCreateSuccess: true, snackTitle: 'created'}
      })
    },
    onContinue() {
      this.isSave = false
      this.$router.push({
        name: 'package-edit',
        params: {id: this.packageObject.id}
      })
    },
    rowClick(subPackage) {
      this.$router.push({name: 'sub-package-edit', params: {id: subPackage.id}})
    },
    setShowPreview(status) {
      this.isPreview = status
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({name: 'package-list'})
    },
    resetAfterChangePackageType() {
      this.isShowPriceFirstCreate = true
      this.isPricePreviewError = false
      this.discountValue = null
      this.selectedTags = []
      this.resetChangePackageTypeState()
    },
    changePackageType(val) {
      this.packageObjOld.package_type = val
      this.resetAfterChangePackageType()
    },
    changeDisplayType(val) {
      // reset
      if (val === displayTypeEnum.MOBILE) {
        this.selectedTags = []
        this.resetChangeDisplayTypeState()
        this.$refs.form.resetValidation()
      }
    },
    changedDiscountType() {
      if (this.packageObject.discount_type === discountTypeEnum.NO_DISCOUNT) {
        this.packageObject.discount = null
        this.packageObject.is_promotion = 0
      } else if (this.discountValue !== null) {
        this.packageObject.discount = this.discountValue
        this.packageObject.is_promotion = 1
      } else {
        this.packageObject.is_promotion = 1
      }
    },
    fullPriceInputed(value) {
      this.fullPrice = value == '' ? 0 : value
      this.isShowPriceFirstCreate = false
    },
    branchRequired(value) {
      if (value instanceof Array && value.length == 0) {
        return 'Branch is required.'
      }
      return !!value || 'Branch is required.'
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    resetDepartment() {
      this.packageObject.department_id = null
    },
    changedSelectedBranch(value) {
      this.packageObject.orgs = value
      this.fetchDepartment(value)
    },
    removeBranch(value) {
      this.packageObject.orgs = this.packageObject.orgs.filter(
        org => value !== org
      )
    },
    checkThaiContentExpand() {
      if (this.isThaiContentExpand) {
        this.isThaiContentExpand = false
      } else {
        this.isThaiContentExpand = true
      }
    },
    checkEngContentExpand() {
      if (this.isEngContentExpand) {
        this.isEngContentExpand = false
      } else {
        this.isEngContentExpand = true
      }
    },
    showDeletePopup() {
      this.setDeleteId(this.packageObject.id)
      this.setDeletePopup(true)
    },
    showSubPackageDeletePopup(subPackageId) {
      this.setSubPackageDeleteId(subPackageId)
      this.setSubPackageDeletePopup(true)
    },
    showResetPopup() {
      this.isReset = true
    },
    async confirmDelete() {
      const result = await this.deletePkg()
      if (result) {
        this.$router.push({
          name: 'package-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.deleteError = true
      }
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    async confirmDeleteSubPackage() {
      const deleteStatus = await this.deleteSubPackage()
      await this.fetch(this.packageObject.id)
      if (deleteStatus) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDeleteErrorAlert() {
      this.deleteError = false
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    closeDeletePopupSubPackage() {
      this.setSubPackageDeletePopup(false)
    },
    closeResetPopup() {
      this.isReset = false
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    isNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    startDateChange(event) {
      if (event) {
        this.packageObject.period_start_time = ''
        this.packageObject.period_end_date = ''
        this.packageObject.period_end_time = ''
        this.packageObject.validity_start_date = ''
        this.packageObject.validity_start_time = ''
        this.packageObject.validity_end_date = ''
        this.packageObject.validity_end_time = ''
      }
    },
    startTimeChange(event) {
      if (event) {
        this.packageObject.period_end_time = ''
        this.packageObject.validity_start_time = ''
        this.packageObject.validity_end_time = ''
      }
    },
    endDateChange(event) {
      if (event) {
        this.packageObject.period_end_time = ''
        this.packageObject.validity_end_date = ''
        this.packageObject.validity_end_time = ''
      }
    },
    endTimeChange(event) {
      if (event) {
        this.packageObject.validity_end_time = ''
      }
    },
    validityStartDateChange(event) {
      if (event) {
        this.packageObject.validity_start_time = ''
        this.packageObject.validity_end_date = ''
        this.packageObject.validity_end_time = ''
      }
    },
    validityStartTimeChange(event) {
      if (event) {
        this.packageObject.validity_end_time = ''
      }
    },
    validityEndDateChange(event) {
      if (event) {
        this.packageObject.validity_end_time = ''
      }
    },
    discountChange(event) {
      this.discountValue = this.packageObject.discount

      if (event != '' && Number.isInteger(parseInt(event))) {
        this.packageObject.discount = Math.floor(parseInt(event))
        this.discountValue = this.packageObject.discount
        return
      }
    },
    setIsErrorDiscountValue() {
      if (
        this.packageObject.discount === null &&
        this.packageObject.discount_type !== discountTypeEnum.NO_DISCOUNT
      ) {
        this.isDiscountError = true
      }
    },
    blurDiscountValueError() {
      this.setIsErrorDiscountValue()
    },
    setDiscountValueError(value) {
      this.isDiscountError = value
    },
    close() {
      this.isLeave = false
      this.to = null
    },
    confirm() {
      this.isLeave = false
      this.$router.push(this.to)
    },
    allowedDates(val) {
      return moment(this.packageObject.period_start_date).isSameOrBefore(
        moment(val)
      )
    },
    allowedValidityStartDates(val) {
      return moment(this.packageObject.period_start_date).isSameOrBefore(
        moment(val)
      )
    },
    allowedValidityEndDates(val) {
      if (
        moment(this.packageObject.validity_start_date).isAfter(
          moment(this.packageObject.period_end_date)
        )
      ) {
        return moment(this.packageObject.validity_start_date).isSameOrBefore(
          moment(val)
        )
      } else {
        return moment(this.packageObject.period_end_date).isSameOrBefore(
          moment(val)
        )
      }
    },
    startTimeRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanCurrentRule(
            val,
            this.packageObject.period_start_date,
            'The publish start time must be later than the current time.'
          )
    },
    endTimeRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanStartRule(
            val,
            this.packageObject.period_start_time,
            this.packageObject.period_start_date,
            this.packageObject.period_end_date,
            'The publish end time must be later than the publish start time.'
          )
    },
    validityStartTimeMoreThanCurrentRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanCurrentRule(
            val,
            this.packageObject.validity_start_date,
            'The validity start time must be later than the current time.'
          )
    },
    validityStartTimeMoreThanEqualStartRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanEqualStartRule(
            val,
            this.packageObject.period_start_time,
            this.packageObject.period_start_date,
            this.packageObject.validity_start_date,
            'The validity start time must be later than or equal the publish start time.'
          )
    },
    validityStartTimeMoreThanEndRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanStartRule(
            val,
            this.packageObject.validity_start_time,
            this.packageObject.validity_start_date,
            this.packageObject.validity_end_date,
            'The validity end time must be later than the validity start time.'
          )
    },
    publishEndTimeMoreThanEndRule(val) {
      return this.isDisableAfterPublish || this.disabledEdit
        ? true
        : timeMoreThanEqualStartRule(
            val,
            this.packageObject.period_end_time,
            this.packageObject.period_end_date,
            this.packageObject.validity_end_date,
            'The validity end time must be later than or equal the publish end time.'
          )
    },
    imageRules(lang, val) {
      if (this.isEditMode) {
        this.setImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setImageError({lang, isError: false, message: ''})
        return
      }
    },
    coverImageRules(lang, val) {
      if (this.isEditMode) {
        this.setCoverImageError({lang, isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setCoverImageError({
          lang,
          isError: true,
          message: 'Image is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setCoverImageError({
            lang,
            isError: true,
            message: 'Image is required'
          })
          return
        }
        this.setCoverImageError({lang, isError: false, message: ''})
        return
      }
    },
    showPreview() {
      this.setStyleImage()
      this.previewOrgs = this.packageObject.orgs.map(org =>
        this.organizationList.find(ol => ol.codename === org)
      )
      this.isPreview = true
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isError = false
    },
    async save() {
      this.clickedSave = true
      if (this.showPackageTypeSection) {
        this.$refs.packageTypeForm.validate()
        return
      }

      this.setIsErrorDiscountValue()
      if (!this.isDisabledPreview()) {
        if (this.validate()) {
          this.setStyleImage()

          if (!this.isEditMode) {
            let status = await this.create(this.packageObject)
            if (status) {
              if (this.isGroupPackage) return (this.isSave = true)
              return this.onCreatedCancel()
            }
            return (this.isError = true)
          }
          if (this.isEditMode) {
            const payload = {pkg: this.packageObject, id: this.$route.params.id}
            let status = await this.updatePkg(payload)
            if (status) {
              this.scrollToTop()
              this.$router.push({
                name: 'package-list',
                query: {isCreateSuccess: true, snackTitle: 'edited'}
              })
            }
            this.scrollToTop()

            return (this.isError = true)
          }
        }
      } else {
        this.$refs.form.validate()
        await this.imageRules(
          this.packageObject.contents[0].code,
          this.packageObject.contents[0].thumbnail
        )
        await this.imageRules(
          this.packageObject.contents[1].code,
          this.packageObject.contents[1].thumbnail
        )
        await this.coverImageRules(
          this.packageObject.contents[0].code,
          this.packageObject.contents[0].cover_image
        )
        await this.coverImageRules(
          this.packageObject.contents[1].code,
          this.packageObject.contents[1].cover_image
        )
      }
    },
    imageRule(val) {
      if (this.isEditMode) {
        if (isUrl(val)) {
          return true
        } else {
          if (val != '') {
            return true
          }
          return 'Imate is required'
        }
      } else {
        if (!val || val.size > 1040000) {
          return 'Image size should be less than 1 MB!'
        }
        if (!val || !String(val.size).includes('image')) {
          return 'Only support image file!'
        } else return true
      }
    },
    selectBranch(data) {
      this.packageObject.orgs = data
    },
    syncSelectedData(data) {
      this.packageObject.orgs = convertSelectedData(
        data,
        this.orgObject,
        this.packageObject.orgs
      )
    },
    isDisabledPreview() {
      const IS_ERROR_GROUP_TYPE =
        this.isPackageTypeError ||
        !this.selectedBranch ||
        this.isDepartmentError ||
        this.isAvailabilityStatusError ||
        this.isPublishStartDateError ||
        this.isPublishStartTimeError ||
        this.isPublishEndDateError ||
        this.isPublishEndTimeError ||
        this.isValidityStartDateError ||
        this.isValidityStartTimeError ||
        this.isValidityEndDateError ||
        this.isValidityEndTimeError ||
        this.isImageThError ||
        this.isImageEnError ||
        this.isCoverImageThError ||
        this.isCoverImageEnError ||
        this.packageObject.contents[0].title === null ||
        this.packageObject.contents[0].title === '' ||
        this.packageObject.contents[1].title === null ||
        this.packageObject.contents[1].title === '' ||
        this.packageObject.contents[0].thumbnail === '' ||
        this.packageObject.contents[1].thumbnail === ''

      const IS_ERROR_SINGLE_TYPE =
        IS_ERROR_GROUP_TYPE ||
        this.isPricePreviewError ||
        this.isFullPriceError ||
        this.isDiscountError ||
        this.isCentrixCodeError

      const IS_ERROR_WEB_TYPE = this.packageObject.alias === ''

      if (this.isGroupPackage && IS_ERROR_GROUP_TYPE) {
        return true
      }
      if (!this.isGroupPackage && IS_ERROR_SINGLE_TYPE) {
        return true
      }
      if (this.isWebSection && IS_ERROR_WEB_TYPE) {
        return true
      }
      return false
    },
    async validate() {
      if (this.$refs.form.validate()) {
        return true
      }

      await this.imageRules(
        this.packageObject.contents[0].code,
        this.packageObject.contents[0].thumbnail
      )
      await this.imageRules(
        this.packageObject.contents[1].code,
        this.packageObject.contents[1].thumbnail
      )
      await this.coverImageRules(
        this.packageObject.contents[0].code,
        this.packageObject.contents[0].cover_image
      )
      await this.coverImageRules(
        this.packageObject.contents[1].code,
        this.packageObject.contents[1].cover_image
      )
    },
    addSubPackage() {
      this.setPackageIdInSubPackage(this.packageObject.id)
      this.scrollToTop()
      this.$router.push({name: 'sub-package-create'})
    },
    handleReset() {
      this.isReset = false
      this.componentKey++
      if (this.isEditMode) {
        this.discountValue = this.packageObjOld.discount
        this.selectedTags = this.packageObjOld.tags.map(t => t.value)
        this.resetPackage()
      } else {
        this.resetState('package_type')
        this.resetAfterChangePackageType()
        this.$refs.form.resetValidation()
      }
      this.scrollToTop()
    },
    ...mapActions('subPackage', {
      deleteSubPackage: 'delete',
      fetch: 'fetch',
      resetList: 'resetList',
      setPackageIdInSubPackage: 'setPackageId',
      setSubPackageDeleteId: 'setDeleteId',
      setSubPackageDeletePopup: 'setDeletePopup'
    }),
    ...mapActions('packages', [
      'create',
      'deletePkg',
      'fetchDepartment',
      'generateShareLink',
      'resetChangeDisplayTypeState',
      'resetChangePackageTypeState',
      'resetPackage',
      'resetState',
      'setAlias',
      'setCoverImageError',
      'setDeleteId',
      'setDeletePopup',
      'setDuplicatePackageId',
      'setEditMode',
      'setImageError',
      'setIsLoading',
      'setOrgInfo',
      'setPackageId',
      'setStyleImage',
      'updatePkg',
      'upload'
    ]),
    showSharePopup() {
      this.isShare = true
    },
    closeSharePopup() {
      this.isShare = false
    }
  }
}
</script>

<style scoped>
.save-package-button {
  color: var(--primary-100-color) !important;
}

.preview-package-button {
  color: var(--primary-text-color) !important;
}

.value-message-icon {
  height: 16px !important;
  width: 16px !important;
}

.value-message {
  margin-top: 2px;
  line-height: 14px;
}

.branchChipText {
  color: var(--primary-text-color) !important;
}

.branchList {
  width: 100%;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips >>> input {
  max-height: inherit !important;
  padding: 6px 0 !important;
}
.text-btn {
  color: var(--primary-text-color) !important;
}
.btn-share {
  margin-right: 20px;
}
</style>
